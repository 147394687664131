/* questi container serve solo per la barra laterale che si attiva quando la pagina va oltre lo schermo verticalmente */
.container {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 90vh;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}

.container::-webkit-scrollbar {
    width: 0px;
    background-color: transparent;
}
  
.container::-webkit-scrollbar-thumb {
    background-color: transparent;
}
/* /////////////////////////////////////////// */

.header{
    display: flex;
}

.buttonContainer{
    display: flex;
    gap: 1rem;
}

.title {
    color: var(--black);
    font-size: 32px;
    font-weight: bold;
    margin-right: 15px;
}