.buttonContainer {
    display: flex;
    gap: 1rem;
}

.title {
    color: var(--black);
    font-size: 32px;
    font-weight: bold;
}

.loadingRow {
    text-align: center;
    padding: 40px !important;
    border: none;
    pointer-events: none;
}

.box {
    /* height: 90%; */
    background-color: var(--white);
    padding-bottom: 10px;
    /* padding-right: 20px; */
}