.title {
  color: var(--black);
  font-size: 32px;
  font-weight: bold;
}

.subtitle {
  color: var(--black);
  font-size: 16px;
  font-weight: bold;
}

.subcontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.orderContainer {
  width: 100%;
  height: 150px;
  background: #ffffff;
  /* Line */
  border: 1px solid #D9E5E9;
  border-radius: 10px;
}

.templateContainer {
  width: 20%;
  height: 100%;
  background: #d3d5d6;
  justify-content: center;
  align-items: center;
}

.cardImage {
  width: 70%;
  max-width: 200px;
  height: 100px;
  align-self: center;
  margin: 10px;
  border-radius: 5px;
  background-color: #000000
}

.dialogSubtitle {
  font-size: 1.2rem !important;
  font-weight: normal;
  margin-bottom: 16px !important;
}