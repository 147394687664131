.title {
    font-weight: 500;
    color: var(--black);
}

.cardContainer {
    background-color: var(--black);
    color: white;
    padding: 2rem;
    border-radius: 2rem;
    flex: 1;
    height: 105%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.buttonNewSede {
    cursor: pointer;
}

.buttonNewSede:hover {
    color: var(--black);
    text-decoration: underline;
}