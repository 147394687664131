.containerDashboard {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

}

.title {
    color: var(--black);
    font-size: 32px;
    font-weight: bold;
}