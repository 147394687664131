.loginForm {
	padding: 0px 0px 20px 0px;
	border-radius: 50px;
	background-color: black;
	overflow: hidden;
}

.header {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30%;
	background-color: #00000033;

}

.title {
	text-align: center;
	margin-top: 10px;
	color: black;
}

.textFields {
	width: 100%;
	margin-bottom: 15px !important;
}

.buttons {
	font-size: medium;
	color: black;
}

.font {
	font-size: medium;

}

.form {
	margin: 0 auto;
}

.load {
	margin-left: 10px;
}

.fonts {
	font-size: medium;
	margin-left: 85px;
}

.registerWrapper {
	display: flex;
	margin-top: 30px;
}

.registerContainer {
	flex-grow: 1;
	text-align: right;
	margin-right: 85px;
}

.divider:after,
.divider:before {
	content: "";
	flex: 1;
	height: 1px;
	background: #eee;
}

.hCustom {
	height: calc(100% - 73px);
}

@media (max-width: 450px) {
	.hCustom {
		height: 100%;
	}
}

.formControl:-webkit-autofill {
	-webkit-text-fill-color: #ffffff;
	transition: background-color 5000s ease-in-out 0s;
}



.iconContainer {
	position: absolute;
	left: 60px;
	top: 50%;
	transform: translateY(-50%);
}

.iconContainer i {
	color: #aaa;
	font-size: 20px;
}

.errorMessage {
	color: red;
	font-size: 14px;
	margin-top: 5px;
}