:root {
  --radius: 50px;
  --box-shadow: rgba(0, 0, 0, 0.3) 0 15px 10px -7px;
}

.container {
  /* margin-bottom: 20 %;*/
}

.title {
  text-align: center;
  margin-top: 10px;
  color: black;
}

.labels {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 10% 0;
  color: #000000;
}

.inputGroup {
  box-shadow: var(--box-shadow);
  margin-bottom: 35px;
}

.inputGroup input {
  border-radius: var(--radius) !important;
  border: none !important;
}

.inputGroupWithButton {
  /* box-shadow: var(--box-shadow); */
  margin-bottom: 15px !important;
}


.inputGroupWithButton input {
  /* border-radius: var(--radius) 0 0 var(--radius) !important; */
  /* border: none !important; */
}

.inputGroupWithButton button,
.inputGroupWithButton button:active,
.inputGroupWithButton button:focus {
  /* border-radius: 0 var(--radius) var(--radius) 0 !important; */
  /* border: none !important; */
  padding: 0 5px !important;
  /* background-color: #fff !important; */
  /* outline: none !important; */
}

.inputGroupWithButton button svg {
  fill: var(--mdb-gray);
}

.divider {
  border-color: #000000 !important;
}

a {
  color: var(--black);
  font-weight: 700 !important;
}