@import '../../../../colors.css';

.buttonContainer{
    display: flex;
    gap: 1rem;
}

.title {
    color: var(--black);
    font-size: 32px;
    font-weight: bold;
}

.headerContainer {
    display: flex;
    align-items: center;
    font-size: 1.5em;
    font-weight: bold;

}

.logo {
    background-color: var(--black);
    color: white;
    border-radius: 25%;
    min-width: 2.5rem;
    min-height: 2.5rem;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: bold;
    margin: 1rem;
}

.logoFooterCard {
    border-radius: 25%;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: bold;
}

.circle-picker span div span div[title~="#fff"] {
    border: 1px solid #999;
}

.circlePickerCustom {
    background-color: #E5E4E2;
    padding-left: 10px;
    padding-top: 15px;
    border-radius: 6px;
    margin-right: 0 !important;
}

.white-selected span div span div[title~="#fff"] {
    box-shadow: #999 0 0 0 3px inset, 0 0 5px #999 !important;
}

.chipSelected {
    color: white;
    background-color: black;
}

