.container {
    
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: 700;
    width: fit-content;
    padding: 0 5px;
}

.green{
    background-color: var(--greenAccent-200);
}

.orange{
    background-color: var(--orangeAccent-200);
}

.gray{
    background-color: var(--primary-700);
}

.red{
    background-color: var(--orangeAccent-300);
}