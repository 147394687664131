.rowStyle {
  color: black;
  font-weight: 400;
  height: 3vw;
  vertical-align: middle !important;
  width: 6px !important;
}


.rowStyleRequest {
  color: black;
  font-weight: 400;
  vertical-align: middle !important;
}

.requestSpan {
  background-color: var(--gray-300);
  padding: 5px 15px;
  border-radius: 2rem;
}

.rowStyleFirstElement {
  color: black;
  font-weight: 800;
  height: 3vw;
  vertical-align: middle !important;
  width: 60px !important;
}

.thead {
  background-color: var(--primary-500) !important;
  color: black !important;
  border-bottom: var(--primary-500) !important;
  height: 3vw !important;
  vertical-align: middle !important;
  font-weight: 400;
}

.loadingRow {
  text-align: center;
  padding: 40px !important;
  border: none;
  pointer-events: none;
}

.imageDiv {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  margin-right: 5px;
}