.orderContainer {
  width: 100%;
  height: 150px;
  background: #ffffff;
  /* Line */
  border: 1px solid #D9E5E9;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
}

.templateContainer {
  width: 20%;
  height: 100%;
  background: #d3d5d6;
  justify-content: center;
  align-items: center;
}

.cardImage {
  width: 70%;
  max-width: 200px;
  height: 100px;
  align-self: center;
  margin: 10px;
  border-radius: 5px;
  background-color: #000000
}

.productItem {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 15px 5px;
  margin: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 25%;
  min-width: 200px;
  color: var(--gray-600)
}

.priceItem {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  margin-right: 0;
  margin: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 120px;
  min-width: 80px;
  color: var(--gray-600)
}


.cardItemSelected {
  border-color: #000;
  border: 2px solid #000;
  color: var(--black)
}

.radioSubtitle {
  font-size: 0.8em;
  font-weight: normal;
}

.radioTitle {
  font-size: 1.5em;
  font-weight: bold;
}

.radioContent {
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.cardStyle {
  display: flex;
  flex-wrap: wrap !important;
  margin: 30px 0;
  padding: 2rem;
  border: 2px solid var(--gray-300) !important;
  border-radius: 0 !important;
}

.cardTitle {
  margin-bottom: 15px;
}