@import '../../colors.css';

.cardContainer {
    display: flex;
    flex: 1;
    height: 9rem !important;
    border-radius: 0.7rem;
    padding: 1rem;
    position: relative;
    /* cursor: pointer; */
    flex-direction: column;
    line-height: 22px;
    gap: 0.5rem;
    justify-content: space-evenly;
}

.cardTitle {
    font-size: 1.15rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.cardContent {
    display: flex;
    justify-content: space-between;
}

.cardValue {
    font-size: 2.85rem;
    font-weight: 500;
    font-style: normal;
}

.cardTotal {
    display: flex;
    gap: 0.8rem;
    align-items: baseline;
    align-self: flex-end;

}

.cardNotification {
    height: 1.5em;
    width: 7em;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    font-weight: 700;
    font-size: 0.8em;
}

.cardButton {
    color: var(--black);
    border-radius: 100%;
    width: 2.85rem;
    height:  2.85rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 700;
    cursor: pointer;
    border: dashed 1px var(--black);
}