@import url('https://fonts.cdnfonts.com/css/circular-std?styles=17909,17911,17907,17905');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0px;
}

html,
body,
#root {
    margin: 0;
    height: 100%;
    width: 100%;
    /* overflow: hidden; */
    font-weight: 450;
    font-size: 14px;
    font-family: 'Circular Std', sans-serif;
}

::-webkit-scrollbar {
    width: 10px;
}

/*TRACK*/
::-webkit-scrollbar-track {
    background: #e0e0e0;
}

/* HANDLE */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.text-default-black {
    color: var(--default-black);
}