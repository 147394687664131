.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.primarySide {
  display: flex;
  background: #063e40;
  background: linear-gradient(90deg,#063e40 0,#309367 41.37%,#a9d59e 100%);
  align-items: center;
  justify-content: center;
  padding: 0 6%;
  font-size: 1.1rem;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10%;
}

.title {
  font-weight: 700;
  margin-top: 4%;
}

.description {
  text-align: justify;
  font-size: 1rem;
  margin-bottom: 5%;
  /* overflow: scroll; */
}

.whiteSide {
  background-color: offwhite;
  display: flex;
  align-items: center;
  justify-content: center;
}


.labels {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 25px 0;
}