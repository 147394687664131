.socialContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.iconStyleActive {
  color: #ffffff !important;
  margin: 0 5% !important;
  font-size: 1.5em !important;
}

.imageContainer {
  overflow: hidden;
  position: relative;
  border-radius: 1em;
  margin-bottom: 2em;
}

.imageContainer img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

.infoBox {
  display: flex;
  border: 0.12em solid #e1ebed;
  padding: 1em;
  margin: 1em 0;
  border-radius: 1em;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  box-shadow: 0 1px #0000001a;
  cursor: pointer;
}

.infoBoxMap {
  display: flex;
  border: 0.12em solid #e1ebed;
  padding: 1em;
  margin: 1em 0;
  border-radius: 1em;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  box-shadow: 0 1px #0000001a;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.socialBox {
  display: flex;
  flex-direction: column;
  border: 0.12em solid #e1ebed;
  padding: 1em;
  margin: 1em 0;
  border-radius: 1em;
  align-items: center;
  background-color: var(--white);
  box-shadow: 0 1px #0000001a;
  cursor: pointer;

}

.background {
  background-color: var(--primary-400);
  width: 100%;
  height: 100% !important;
  font-size: 16px;
  overflow: scroll;
}

.container {
  /* background-color: black; */
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 !important;
  /* overflow: scroll; */
  justify-content: space-between;
  /* width: 100%; */
  /* height: 100%; */
  margin-bottom: 100px;
}

.topSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 10% 0 0 0;
  flex: 1;
  justify-content: center;

}

.userName {
  color: var(--black);
  font-size: 1.875em;
  line-height: 2.25em;
  font-weight: 800;
  text-transform: capitalize;
}

.userProfession {
  color: var(--black);
  display: flex;
  font-size: 1.125em;
  font-weight: 500;

}

.containerImgInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.closeButtonModal {
  position: absolute;
  left: 15px;
  top: 15px;
  width: 20px;
  height: 20px;
  background-color: black;
  color: white;
}

.closeButtonModal:hover {
  background-color: lightgray
}

.infoTitles {
  font-size: 1.125em;
  line-height: 1.75em;
  font-weight: 700;
  /* margin-bottom: 0.5em; */
}

.infoSubTitle {
  font-size: 1em;
  line-height: 1.5em;
  /* font-weight: 500; */
  margin-top: 0.5em;

}

.bottomSection {
  width: 100%;
  height: 65%;
  border-radius: 20px 20px 0 0;
  padding: 5%;
  color: black;
}

.buttonBars {
  background-color: black;
  width: 25%;
  height: 4em;
  padding: 10px;
  border-radius: 1em;
  display: flex;
  justify-content: space-evenly;
  gap: 1%;
  margin-bottom: 20px;
  position: fixed;
  bottom: 0;
}

.iconContainer {
  position: relative;
  padding: 1em;
  cursor: pointer;
}

.topRightIcon {
  position: absolute;
  bottom: -95%;
  right: 5%;
  overflow: hidden;
  /* transform: translate(-50%, -50%); */
}

.loadingRow {
  text-align: center;
  padding: 40px !important;
  border: none;
  pointer-events: none;
}

@media (max-width: 1025px) {
  .buttonBars {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .buttonBars {
    width: 75%;
  }
}