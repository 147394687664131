.buttonContainer{
    display: flex;
    gap: 1rem;
}

.title {
    color: var(--black);
    font-size: 32px;
    font-weight: bold;
}
