/* styles.module.css */
.radioGroupContainer {
  display: flex;
  flex-direction: column;
}

.radioItem {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  margin: 8px 8px 8px 0;
  display: flex;
  /* height: 100px; */
  align-items: center;
  justify-content: center;
  width: 120px;
}

.radioItemSelected {
  border-color: #000;
}

.radioImage {
  width: 100px;
  height: 50px;
  align-self: center;
  margin: 10px;
  border-radius: 5px;
}

.radioContent {
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.radioTitle {
  font-size: 1.5em;
  font-weight: bold;
}

.radioSubtitle {
  font-size: 0.8em;
  font-weight: normal;
}