@import '../colors.css';

.logo {
    background-color: var(--black);
    color: white;
    border-radius: 25%;
    min-width: 2.5rem;
    min-height: 2.5rem;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: bold;
    margin: 1rem;
}

.headerContainer {
    display: flex;
    align-items: center;
    font-size: 1.5em;
    font-weight: bold;
    padding: 10px;
}


.menuItem:hover {
    font-size: 1rem;
    font-weight: 700;
    background-color: white !important;
}

a:focus {
    outline: none;
}


.menu-label {
    font-size: 1rem;
    font-weight: 700;
}

/* button {
    right: 20px;
} */

hr {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}


.boldText {
    font-weight: 700;
    font-size: 1rem;
    border-right: 3px solid black;
}