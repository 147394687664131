.tableHead {
    background-color: var(--primary-500);
    position: sticky;
    top: 0;
    /* border-bottom: var(--primary-500); */
}

.boldCell {
    font-weight: bold;

}

.profileImage {
    width: 2rem;
    margin-right: 20px;
    border-radius: 25%;
}

.tableHead th {
    font-weight: bold !important;
    color: var(--black);
}

.tableBody td {
    text-justify: inter-word !important;
    height: 8vh;
    color: var(--black);
}

.rowLink {
    text-decoration: none;
    color: inherit;
    cursor: pointer;

}