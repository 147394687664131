@import '../colors.css';

.containerTopbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary--500);
    height: 50px;
    padding: 40px 0;
    margin: 0;
}

.title {
    color: var(--black);
    font-size: 2.28rem;
    font-weight: bold;
}

.userInfoContainer {
    display: flex;
    align-items: center;
}

.userText {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    /* margin-bottom: 10px;*/
    font-size: large;
    align-items: flex-end;
}

.userCompany {
    font-size: small;
    color: var(--gray);
}

.userImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.logoutIcon {
    cursor: pointer;
    font-size: 30px !important;
    color: var(--black);
}