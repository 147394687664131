.headerContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .userInformation {
    display: flex;
    flex-direction: row;
    align-items: center;
   
  }
  
  .textContainer {
    display: flex;
    font-size: 2.28rem;
    font-weight: 700;
    color: var(--black) ;
  }
  
  .imageContainer{
    width: 2rem;
    margin-right: 10px;
    border-radius: 25%;
  }
